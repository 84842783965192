<template>
	<ValidationObserver>
		<form @submit.prevent="saveArticle">
			<b-card title="Article Detail">
				<div class="form-group form-row">
					<label class="col-12 col-md-4 col-form-label">Title*</label>
					<div class="col">
						<validation-provider
							name="title"
							rules="required"
							v-slot="{ errors, classes }"
						>
							<input 
								type="text" 
								:class="classes"
								:state="errors.length > 0 ? false:null"
								class="form-control" 
								v-model="formArticle.title"
							>
							<small
								v-for="(validation, index) in validations.title"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-12 col-md-4 col-form-label">Thumbnail*</label>
					<div class="col">
						<validation-provider
							name="image"
							rules="required"
							v-slot="{ errors }"
						>
							<b-form-file
								placeholder="Choose a file or drop it here..."
								drop-placeholder="Drop file here..."
								:state="errors.length > 0 ? false:null"
								@change="changePhoto($event , 'thumbnail')"
							/>
							<!-- <p class="images__hint"><i>Image Size: 200 x 200 pixel</i></p> -->
							<small
								v-for="(validation, index) in validations.thumbnail"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
						<div
							v-if="formArticle.thumbnail"
							class="mt-1 relative"
						>
							<img
								:src="thumbnail_url"
								class="img-fluid"
							>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle"
								@click="cancelPhoto('thumbnail')"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-12 col-md-4 col-form-label">Image*</label>
					<div class="col">
						<validation-provider
							name="image"
							rules="required"
							v-slot="{ errors }"
						>
							<b-form-file
								placeholder="Choose a file or drop it here..."
								drop-placeholder="Drop file here..."
								:state="errors.length > 0 ? false:null"
								@change="changePhoto($event , 'image')"
							/>
							<!-- <p class="images__hint"><i>Image Size: 400 x 400 pixel</i></p> -->
							<small
								v-for="(validation, index) in validations.image"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
						<div
							v-if="formArticle.image"
							class="mt-1 relative"
						>
							<img
								:src="image_url"
								class="img-fluid"
							>
							<b-button
								variant="gradient-danger"
								class="btn-icon rounded-circle"
								@click="cancelPhoto('image')"
							>
								<feather-icon icon="XIcon" />
							</b-button>
						</div>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-12 col-md-4 col-form-label">Konten*</label>
					<div class="col">
						<validation-provider
							name="Tanggal Publish"
							rules="required"
							v-slot="{ errors }"
						>
							<quill-editor
								v-model="formArticle.content"
								:options="snowOption"
								:state="errors.length > 0 ? false:null"
							/>
							<small
								v-for="(validation, index) in validations.content"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-12 col-md-4 col-form-label">Tanggal Publish:*</label>
					<div class="col">
						<validation-provider
							name="Tanggal Publish"
							rules="required"
							v-slot="{ errors }"
						>
							<flat-pickr
								v-model="formArticle.published_at"
								class="form-control"
								:state="errors.length > 0 ? false:null"
								:config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
							/>
							<small
								v-for="(validation, index) in validations.published_at"
								:key="`errorName${index}`"
								class="text-danger"
							>{{ validation }}</small>
						</validation-provider>
					</div>
				</div>
				<div class="form-group form-row">
					<label class="col-12 col-md-4 col-form-label">Tanggal Publish Berakhir:*</label>
					<div class="col">
						<flat-pickr
							v-model="formArticle.unpublished_at"
							class="form-control"
							:config="{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}"
						/>
					</div>
				</div>
			</b-card>
			<!-- <b-card>
				<b-row>
					<b-col cols="12" md="6" v-for="(items, key) in segments" :key="key">
						<input type="hidden" :value="items.key" :ref="`val${items.key}`">
						<div class="form-group">
							<label>{{ items.label }}</label>
							<validation-provider
								name="segments"
								rules="required"
								v-slot="{ errors, classes }"
							>
								<input 
									type="email" 
									class="form-control" 
									:ref="items.key"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-if="items.key == 'email'"
									v-model="inputSegment[key]"
								>
								<v-select
									label="name"
									v-model="inputSegment[key]"
									:options="optionSearch"
									v-if="vSelectTagKeys.includes(items.key)"
									placeholder="-- Pilih --"
									:ref="items.key"
									@search="onSearch"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:reduce="option => option.name"
								/>
								<select 
									class="form-control" 
									v-model="inputSegment[items.key]"
									:class="classes"
									:state="errors.length > 0 ? false:null"
									v-if="regularSelectTagKeys.includes(items.key)"
									:ref="items.key"
								>
									<option value="">Any</option>
									<option :value="select.value" v-for="(select , index) in items.available_answer" :key="index">
										{{ select.label }}
									</option>
								</select>
								<small
									v-for="(validation, key) in validations['segmentations.' + key + '.key']"
									:key="`errorName${key}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</b-col>
					<b-col cols="12" md="6">
						<div class="form-group">
							<label>Partnership</label>
							<validation-provider
								name="partners"
								rules="required"
								v-slot="{ errors }"
							>
								<v-select
									v-model="partnerSelected"
									:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
									:state="errors.length > 0 ? false:null"
									label="name"
									multiple
									placeholder="-- Pilih Partner --"
									:options="partners"
								/>
								<small
									v-for="(validation, index) in validations.partners"
									:key="`errorName${index}`"
									class="text-danger"
								>{{ validation }}</small>
							</validation-provider>
						</div>
					</b-col>
				</b-row>
			</b-card> -->
			<b-col cols="12" class="p-0 mt-2">
				<button
					variant="primary"
					class="btn waves-effect waves-float waves-light btn-primary"
				>
					Save
				</button>
				&nbsp;
				&nbsp;
				<a
					@click="handleBackButton()"
					variant="secondary"
					class="btn waves-effect waves-float waves-light btn-secondary"
				>
					Cancel
				</a>
			</b-col>
		</form>
	</ValidationObserver>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import vSelect from 'vue-select'
import {
	BCard,  
	BRow, 
	BCol,
	BFormFile,
	BButton,
	BFormSelect
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import _ from 'lodash'
import { ValidationProvider, ValidationObserver, configure } from 'vee-validate'
import { required } from '@validations'
import { successNotification, errorNotification } from '@/auth/utils'
configure({
	classes: {
		valid: 'is-valid',
		invalid: 'is-invalid',
		dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
		// ...
	}
})
export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormFile,
		BButton,
		BFormSelect,
		quillEditor,
		vSelect,
		flatPickr,
		ValidationProvider,
		ValidationObserver,
	},
	setup() {
		return {
			successNotification, 
			errorNotification
		}
	},
	mounted() {
		this.loadSegment();
		this.loadPartner();
	},
	data() {
		return {
			required,
			formArticle:{
				title: '',
				content: '',
				image: '',
				thumbnail: '',
				published_at: null,
				unpublished_at: null,
				segmentations: [],
			},
			// segments: [],
			snowOption: {
				theme: 'snow',
			},
			optionSearch :[],
			partners: [],
			inputSegment: [],
			partnerSelected:[],
			image_url: null,
			thumbnail_url: null,
			vSelectTagKeys: [
				'sub_profesi_pengusaha',
				'sub_profesi_profesi',
				'sub_profesi_freelance'
			],
			regularSelectTagKeys: [
				'profesi',
				'sub_profesi_pegawai',
				'punya_npwp',
				'punya_efin',
				'punya_djp',
				'harta',
				'status_menikah',
				'profesi_istri',
				'surat_pisah_harta',
				'punya_anak',
				'profesi_anak'
			],
			validations: '',
		}
	},
	methods: {
		handleBackButton() {
			history.back()
		},

		onSearch(search, loading) {
			if (search.length) {
				loading(true);
				this.multipleSelect(loading, search, this);
			} 
			else {
				this.loadSubprofession()
			}
		},

		multipleSelect: _.debounce((loading, search, vm) => {
			vm.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword='+ search
			).then(response => {
				vm.optionSearch = response.data.data
				loading(false)
			}).catch(() => {
				loading(false)
			})
		}, 300),

		loadSubprofession() {
			this.$http.get('admin/fill-questions/compliting-profile/sources/business-types?keyword'
			).then(response => {
				this.optionSearch = response.data.data
			})
		},

		async loadPartner() {
			this.$http.get('admin/partners')
			.then(response => {
				response.data.data.forEach(element => {
					if (element.name == 'Hipajak') {
						this.partnerSelected.push(element) 
					}
					this.partners.push({'uuid':element.uuid, 'name':element.name})
				});
			})
		},

		// async loadSegment() {
		// 	await this.$http.get('admin/segmentations')
		// 	.then(response => {
		// 		this.segments = response.data.data
		// 		for (const object of response.data.data) {
		// 			this.inputSegment[object.key] = ''
		// 		}
		// 	})
		// 	for (let index = 0; index < this.segments.length; index++) {
		// 		const element = this.segments[index];
		// 		this.$refs[element.key][0].value = this.formArticle.segmentations[index]?.value || ''
		// 		console.log(this.$refs[element.key][0].value, 'ini data');
		// 	}
		// },

		changePhoto(event , name) {
			if (name === 'image') {
				this.formArticle.image = event.target.files[0]
				this.showPhoto(event.target.files[0], name)
			} else {
				this.formArticle.thumbnail = event.target.files[0]
				this.showPhoto(event.target.files[0], name)
			}
		},

		showPhoto(file , name) {
			const reader = new FileReader()
			reader.onload = e => {
				if (name === 'image') {
					this.image_url = e.target.result
				} else {
					this.thumbnail_url = e.target.result
				}
			}
			reader.readAsDataURL(file)
		},

		cancelPhoto(name) {
			if (name === 'image') {
				this.image_url = null
			} else {
				this.thumbnail_url = null
			}
		},

		saveArticle() {
			const payload = new FormData()
			payload.append("title", this.formArticle.title)
			payload.append("content", this.formArticle.content)
			payload.append("image", this.formArticle.image)
			payload.append("thumbnail", this.formArticle.thumbnail)
			payload.append("published_at", this.formArticle.published_at)
			payload.append("unpublished_at", this.formArticle.unpublished_at)
			for (let index = 0; index < this.partnerSelected.length; index++) {
				const partnerUuid = this.partnerSelected[index]['uuid'];
				payload.append('partners[]', partnerUuid)
			}
			// for (let index = 0; index < this.segments.length; index++) {
			// 	const element = this.segments[index];
			// 	payload.append("segmentations[" + index + "][key]", this.$refs[`val${element.key}`][0].value)
			// 	payload.append("segmentations[" + index + "][value]", this.$refs[element.key][0].value || '')
			// }
			this.$http.post('admin/articles', payload,{
				headers: { 'Content-Type': 'multipart/form-data' },
			})
			.then(response => {
				successNotification(this, 'Success', 'Article Berhasil dibuat!')
				this.$router.push({ name: 'article' })
				
			}).catch(error => {
				if (error.response.data.meta.validations) {
					this.validations = error.response.data.meta.validations
					errorNotification(this, 'Title', this.validations.title.join(' '))
					errorNotification(this, 'Thumbnail', this.validations.thumbnail.join(' '))
					errorNotification(this, 'Image', this.validations.image.join(' '))
					errorNotification(this, 'Konten', this.validations.content.join(' '))
					errorNotification(this, 'Tanggal Publish', this.validations.published_at.join(' '))
				}
			})
		}
	},
	async created() {
		await this.loadSubprofession()
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
	.images__hint {
		font-size: 10px;
		margin-bottom: 0;
	}
	.btn-icon {
		position: absolute;
		right: 0;
	}
	.form-check-inline {
		flex-wrap: wrap;
	}
	.custom-checkbox.custom-control {
		padding-left: 1.8rem;
		margin-right: 1.8rem;
		margin-bottom: 1rem;
	}
</style>