var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveArticle.apply(null, arguments)}}},[_c('b-card',{attrs:{"title":"Article Detail"}},[_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v("Title*")]),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formArticle.title),expression:"formArticle.title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","state":errors.length > 0 ? false:null},domProps:{"value":(_vm.formArticle.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formArticle, "title", $event.target.value)}}}),_vm._l((_vm.validations.title),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v("Thumbnail*")]),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.changePhoto($event , 'thumbnail')}}}),_vm._l((_vm.validations.thumbnail),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])}),(_vm.formArticle.thumbnail)?_c('div',{staticClass:"mt-1 relative"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.thumbnail_url}}),_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"gradient-danger"},on:{"click":function($event){return _vm.cancelPhoto('thumbnail')}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1):_vm._e()],1)]),_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v("Image*")]),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","state":errors.length > 0 ? false:null},on:{"change":function($event){return _vm.changePhoto($event , 'image')}}}),_vm._l((_vm.validations.image),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])}),(_vm.formArticle.image)?_c('div',{staticClass:"mt-1 relative"},[_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.image_url}}),_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"gradient-danger"},on:{"click":function($event){return _vm.cancelPhoto('image')}}},[_c('feather-icon',{attrs:{"icon":"XIcon"}})],1)],1):_vm._e()],1)]),_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v("Konten*")]),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"Tanggal Publish","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{attrs:{"options":_vm.snowOption,"state":errors.length > 0 ? false:null},model:{value:(_vm.formArticle.content),callback:function ($$v) {_vm.$set(_vm.formArticle, "content", $$v)},expression:"formArticle.content"}}),_vm._l((_vm.validations.content),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v("Tanggal Publish:*")]),_c('div',{staticClass:"col"},[_c('validation-provider',{attrs:{"name":"Tanggal Publish","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null,"config":{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}},model:{value:(_vm.formArticle.published_at),callback:function ($$v) {_vm.$set(_vm.formArticle, "published_at", $$v)},expression:"formArticle.published_at"}}),_vm._l((_vm.validations.published_at),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)]),_c('div',{staticClass:"form-group form-row"},[_c('label',{staticClass:"col-12 col-md-4 col-form-label"},[_vm._v("Tanggal Publish Berakhir:*")]),_c('div',{staticClass:"col"},[_c('flat-pickr',{staticClass:"form-control",attrs:{"config":{ enableTime: true,dateFormat: 'Y-m-d H:i:ss'}},model:{value:(_vm.formArticle.unpublished_at),callback:function ($$v) {_vm.$set(_vm.formArticle, "unpublished_at", $$v)},expression:"formArticle.unpublished_at"}})],1)])]),_c('b-col',{staticClass:"p-0 mt-2",attrs:{"cols":"12"}},[_c('button',{staticClass:"btn waves-effect waves-float waves-light btn-primary",attrs:{"variant":"primary"}},[_vm._v(" Save ")]),_vm._v("     "),_c('a',{staticClass:"btn waves-effect waves-float waves-light btn-secondary",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.handleBackButton()}}},[_vm._v(" Cancel ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }